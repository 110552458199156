<script>
	import { page } from '$app/stores';
	$: pathname = $page?.url?.pathname?.endsWith('/')
		? $page?.url?.pathname?.slice(0, -1)
		: $page?.url?.pathname ?? '';
	$: url = $page?.url?.origin + pathname;
</script>

<svelte:head>
	<meta property="og:site_name" content="Indow" />
	<meta property="og:url" content={url} />
	<meta
		property="og:title"
		content={$page?.data?.title ? 'Indow' : `${$page?.data?.title} | Indow`}
	/>
	<meta
		property="twitter:title"
		content={$page?.data?.title ? 'Indow' : `${$page?.data?.title} | Indow`}
	/>
	{#if $page?.data?.description}
		<meta property="og:description" content={$page?.data?.description} />
		<meta property="twitter:description" content={$page?.data?.description} />
	{/if}
	{#if $page?.data?.image}
		<meta property="og:image" content={$page?.data?.image?.url} />
		<meta property="og:image:width" content={$page?.data?.image?.width} />
		<meta property="og:image:height" content={$page?.data?.image?.height} />
		<meta property="og:image:type" content={$page?.data?.image?.type} />
		<meta property="og:image:alt" content={$page?.data?.image?.alt} />
	{/if}
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="twitter:card" content="summary_large_image" />
	<meta property="twitter:creator" content="@indowwindows" />
	<meta property="twitter:site" content="@indowwindows" />
</svelte:head>
