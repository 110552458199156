<a
	aria-label="home"
	class="!m-0 grid w-max place-items-center justify-self-start uppercase no-underline hover:text-indow-blue focus:text-indow-blue md:m-4"
	href="/"
>
	<svg
		class="logo h-[1.8rem] fill-indow-white object-contain p-0 transition-colors md:h-[3rem] md:w-auto md:-translate-y-[0.75rem] md:fill-indow-blue"
		viewBox="0 0 517.64001 184.64"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g transform="matrix(1.3333333,0,0,-1.3333333,0,184.64)" id="g10">
			<g transform="scale(0.1)" id="g12">
				<path
					id="path14"
					d="m 3518.45,362.051 c 0,0 -77.01,278.746 -86.7,313.844 -9.7,35.093 -26.87,58.496 -82.99,58.496 -56.11,0 -75.03,-30.063 -85.35,-64.512 -10.31,-34.465 -94.74,-316.598 -94.74,-316.598 l -100.22,378.16 h -131.52 l 166.95,-547.793 h 122.62 l 117.69,377.606 105.92,-377.606 h 119.79 l 178.02,547.793 h -125.89 l -103.58,-369.39"
				/>
				<path
					id="path16"
					d="m 1546.46,747.094 c -49.41,0 -110.08,-21.485 -148.98,-68.692 v 53.047 H 1276.94 V 183.66 h 122.62 v 296.852 c 0,93.691 22.9,116.406 36.59,129.961 l 1.71,1.738 c 33.55,33.574 76.8,35.254 85.14,35.254 l 2.61,-0.039 c 73.87,0 108.3,-38.738 108.3,-121.981 V 183.66 h 122.61 v 374.156 c 0,180.157 -174.98,189.278 -210.06,189.278"
				/>
				<path
					id="path18"
					d="m 2088.31,260.371 c -100.65,0 -136.49,102.399 -136.49,198.238 0,89.481 23.67,196.114 136.49,196.114 100.64,0 136.51,-101.289 136.51,-196.114 0,-90.429 -23.7,-198.238 -136.51,-198.238 z m 258.05,27.961 c 0,0 0,518.52 0,574.199 0,48.02 -29.51,74.407 -78.27,74.407 -29.28,0 -44.32,0 -44.32,0 V 685.055 c -33.41,39.843 -87.32,62.039 -153.2,62.039 -182,0 -246.59,-155.965 -246.59,-289.524 0,-133.601 59.94,-289.582 228.85,-289.582 76.56,0 135.37,25.672 172.16,74.66 l 0.17,-58.988 h 121.2 c 0,0 0,81.219 0,104.672"
				/>
				<path
					id="path20"
					d="m 2674.01,263.52 c -119.71,0 -144.86,105.531 -144.86,194.05 0,88.5 25.15,194.028 144.86,194.028 119.76,0 144.88,-105.528 144.88,-194.028 0,-88.519 -25.12,-194.05 -144.88,-194.05 z m 0,483.574 c -168.18,0 -272.68,-110.938 -272.68,-289.524 0,-178.621 104.5,-289.582 272.68,-289.582 168.2,0 272.7,110.961 272.7,289.582 0,178.586 -104.5,289.524 -272.7,289.524"
				/>
				<path
					id="path22"
					d="m 1182.15,936.938 c 0,0 -11.98,0 -48.05,0 -43.27,0 -74.6,-24.727 -74.6,-69.422 0,-36.641 0,-67.707 0,-67.707 h 122.65 v 137.129"
				/>
				<path
					id="path24"
					d="m 1059.56,183.66 h 122.59 V 731.449 H 1059.56 V 183.66"
				/>
				<path
					id="path26"
					d="m 3828.51,685.531 h -11.23 v 14.004 h 9.04 c 8.75,0 11.37,-1.609 11.37,-6.133 0,-5.976 -2.03,-7.871 -9.18,-7.871 z m 22.62,6.563 c 0,13.562 -8.91,17.937 -24.36,17.937 h -22.32 V 650.68 h 12.83 v 24.336 h 5.98 l 12.69,-24.336 h 14.14 l -13.71,25.664 c 8.32,0.586 14.75,4.812 14.75,15.75"
				/>
				<path
					id="path28"
					d="m 3826.48,639.008 c -21.9,0 -39.26,16.758 -39.26,41.269 0,24.504 17.36,41.27 39.26,41.27 21.43,0 39.22,-16.766 39.22,-41.27 0,-24.511 -17.79,-41.269 -39.22,-41.269 z m 0,95.383 c -29.18,0 -55.88,-20.129 -55.88,-54.114 0,-33.984 26.7,-54.109 55.88,-54.109 29.16,0 55.86,20.125 55.86,54.109 0,33.985 -26.7,54.114 -55.86,54.114"
				/>
				<path
					id="path30"
					d="m 78.0273,293.391 v 983.679 c 0,15.13 12.2579,27.39 27.3827,27.39 h 459.528 c 15.125,0 27.382,-12.26 27.382,-27.39 v -75.79 H 241.871 c -37.812,0 -68.473,-30.64 -68.473,-68.46 V 266.012 H 105.41 c -15.1248,0 -27.3827,12.258 -27.3827,27.379 z M 608.605,1384.76 H 68.4727 C 30.6602,1384.76 0,1354.12 0,1316.29 V 251.941 C 0,214.129 30.6602,183.48 68.4727,183.48 H 608.605 c 37.813,0 68.473,30.649 68.473,68.461 V 1316.29 c 0,37.83 -30.66,68.47 -68.473,68.47"
				/>
				<path
					id="path32"
					d="M 782.004,1201.28 H 743.129 V 208.211 c 0,-54.07 -56.59,-98.449 -108.477,-98.449 0,0 -428.351,-0.153 -461.254,-0.153 0,-0.168 0.016,-0.339 0.016,-0.507 h -0.016 0.004 -0.004 V 68.4609 C 173.398,30.6602 204.059,0 241.871,0 h 540.133 c 37.809,0 68.476,30.6602 68.476,68.4609 V 1132.82 c 0,37.82 -30.667,68.46 -68.476,68.46"
				/>
			</g>
		</g>
	</svg>
</a>
